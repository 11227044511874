import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ContentRow from '../shared/ContentRow'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'

const CarouselWrapper = styled.div`
  ${props => !props.isMobile && 'display: flex'};
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
  transition: all 300ms ease-in-out;
  margin: ${props => (props.addNegativeMargin ? '-60px 0 0 0' : '0')};
  max-height: 100%;
  line-height: 0;
  position: relative;
  img {
    max-width: 100%;
  }
  .carousel-root {
    max-width: ${props =>
      props.halfpageOnly && !props.isMobile ? '50%' : '100%'};
  }
  .half-page-content {
    background: ${props => props.theme.grey2};
    width: 100%;
    right: 0;
  }
  .carousel .slide {
    max-height: 600px;
    background: #fff;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
  .carousel .control-dots {
    margin: 0;
    padding: 20px;
    .dot {
      box-shadow: none;
      border: 1px solid ${props => props.theme.grey2};
      opacity: 0.8;
      width: 10px;
      height: 10px;
      &.selected {
        border: 1px solid ${props => props.theme.red};
        background: ${props => props.theme.red};
      }
    }
  }
`

const PageImageCarousel = ({
  images,
  halfpageContent,
  addNegativeMargin = false,
  name = 'page-image',
}) => {
  const [isMobile, setIsMobile] = useState()
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const imagesInCarousel = isMobile
    ? images.map(image => image.slider_image_mobile)
    : images.map(image => image.slider_image)
  return (
    <CarouselWrapper
      halfpageOnly={!!halfpageContent}
      addNegativeMargin={addNegativeMargin}
      isMobile={isMobile}
    >
      <Carousel
        autoPlay
        showArrows
        emulateTouch
        useKeyboardArrows
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        interval={3000}
      >
        {imagesInCarousel.map(
          (image, i) =>
            image &&
            image.localFile && (
              <img
                key={i}
                src={image.localFile.childImageSharp.fluid.src}
                alt={name}
              />
            ),
        )}
      </Carousel>
      {halfpageContent && <ContentRow data={halfpageContent} noImage />}
    </CarouselWrapper>
  )
}

export default PageImageCarousel
