/* eslint-disable react/no-danger */
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const RowWrapper = styled(BackgroundImage)`
  background-color: ${props => props.theme.grey5};
`
const NoImageRowWrapper = styled.div`
  background-color: ${props => props.theme.grey2};
`
const RowInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: ${props => (props.isCenter ? '0 80px' : '0 20px')};
  height: 100%;
  min-height: 300px;
  display: grid;
  grid-template-columns: ${props => (props.isCenter ? '1fr' : '1fr 1fr')};
  grid-gap: 160px;
  align-items: center;
  h2 {
    margin: 0 0 10px 0 !important;
    text-align: left;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 22px;
  }
  h2,
  div,
  div p {
    color: ${props => (props.isDark ? '#fff' : '#333')};
  }
  .content {
    ${props => props.isMobile && 'height: 100%'};
    grid-column: ${props => (props.isRight ? '2/2' : '1/1')};
    grid-row: 1/1;
  }
  .blank-col {
    grid-column: ${props => (props.isRight ? '1/1' : '2/2')};
    grid-row: 1/1;
  }
  @media (max-width: 1200px) {
    grid-gap: 80px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: ${props => (props.isMobile ? 'center' : 'flex-end')};
    padding: 0;
    .content,
    .blank-col {
      grid-column: 1/1;
      grid-row: 1/1;
    }
    .content {
      padding: 100px 30px 30px 30px;
      background: linear-gradient(
        180deg,
        ${props =>
            props.isDark ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)'}
          0%,
        ${props =>
            props.isDark ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'}
          100%
      );
    }
  }
`

const ContentRow = ({data, noImage = false}) => {
  const [isMobile, setIsMobile] = useState()
  useEffect(() => {
    if (window.screen.width < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  let bgPos = 'center top'
  if (isMobile) {
    if (data.text_position === 'right') {
      bgPos = 'left top'
    } else {
      bgPos = 'right top'
    }
  }
  if (noImage) {
    return (
      <NoImageRowWrapper
        Tag="section"
        className="row-bg"
        style={{
          backgroundPosition: bgPos,
        }}
      >
        <RowInner isDark isCenter isMobile={isMobile}>
          <div className="content">
            <h2 dangerouslySetInnerHTML={{__html: data.title}} />
            <div dangerouslySetInnerHTML={{__html: data.text}} />
          </div>
          <div className="blank-col" />
        </RowInner>
      </NoImageRowWrapper>
    )
  }
  return (
    <RowWrapper
      Tag="section"
      className="row-bg"
      fluid={data.background_image.localFile.childImageSharp.fluid}
      style={{
        backgroundPosition: bgPos,
      }}
    >
      <RowInner
        isDark={data.mode === 'dark'}
        isRight={data.text_position === 'right'}
      >
        <div className="content">
          <h2 dangerouslySetInnerHTML={{__html: data.title}} />
          <div dangerouslySetInnerHTML={{__html: data.text}} />
        </div>
        <div className="blank-col" />
      </RowInner>
    </RowWrapper>
  )
}
export default ContentRow
