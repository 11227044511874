import React, {useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeader from '../components/Layout/PageHeader'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import MainProducts from '../components/MainProducts'
import PageImageCarousel from '../components/PageImageCarousel/page-image-carousel'
import EllipsisLoader from '../components/EllipsisLoader'
import arrow from '../images/icons/icon-arrow-circle-red.svg'
import {Inner} from '../components/styles'
import YouTube from 'react-youtube'

const YoutubeWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  div {
    width: 100%;
    height: 675px;
    @media (max-width: 1080px) {
      height: calc((100vw - 100px) * 0.652);
    }
    iframe {
      width: 100%;
      height: 675px;
      @media (max-width: 1080px) {
        height: calc((100vw - 100px) * 0.652);
      }
    }
  }
`

const YoutubeInner = styled.div`
  max-width: 1340px;
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  div {
    width: 100%;
    height: 675px;
    padding: 0 10px;
    @media (max-width: 1080px) {
      height: calc((100vw - 140px) * 0.652);
    }
    iframe {
      width: 100%;
      height: 675px;
      @media (max-width: 1080px) {
        height: calc((100vw - 140px) * 0.652);
      }
    }
  }
  img {
    cursor: pointer;
    margin: auto;
    align-self: center;
    max-width: 60px;
    height: 100%;
    width: 7vw;
  }
  img:hover {
    transform: scale(1.1);
    &.rotate {
      transform: scale(1.1) rotate(180deg);
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
  .loader {
    position: absolute;
  }
`
const PageWrapper = styled.div`
  margin: 25px 0;
  @media (max-width: 768px) {
    .wp-block-column {
      padding: 0;
    }
    .wp-block-columns.has-2-columns.no-padding {
      display: grid;
      grid-template-columns: 1fr;
    }
    .wp-block-image.no-margin {
      margin: 40px 0;
    }
  }
`

const AboutPage = ({location}) => {
  const [activeVideo, setActiveVideo] = useState(0)
  const [loading, setLoading] = useState(0)
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 123}) {
        title
        content
        acf {
          page_slider {
            slider_image {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxHeight: 700, srcSetBreakpoints: [200]) {
                    src
                  }
                }
              }
            }
            slider_image_mobile {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 400, srcSetBreakpoints: [200]) {
                    src
                  }
                }
              }
            }
          }
          videos {
            video_title
            youtube_link
          }
          about_us_intro
        }
      }
      seo: wpgraphql {
        pageBy(pageId: 123) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  const prevImg = () => {
    if (activeVideo <= 0) {
      setActiveVideo(data.wp.acf.videos.length - 2)
    } else {
      setActiveVideo(prev => prev - 1)
    }
    setLoading(true)
  }
  const nextImg = () => {
    if (activeVideo >= data.wp.acf.videos.length - 2) {
      setActiveVideo(0)
    } else {
      setActiveVideo(prev => prev + 1)
    }
    setLoading(true)
  }

  return (
    <Layout location={location} flush>
      <SEO
        location={location}
        seo={data.seo.pageBy.seo}
        title="About Kooi Beds"
      />
      <PageHeader location={location}>{data.wp.title}</PageHeader>
      {data.wp.acf.page_slider && (
        <PageImageCarousel images={data.wp.acf.page_slider} addNegativeMargin />
      )}
      <Inner>
        <PageWrapper
          className="content"
          dangerouslySetInnerHTML={{__html: data.wp.acf.about_us_intro}}
        />
        {data.wp.acf.videos && data.wp.acf.videos.length && (
          <YoutubeWrapper>
            <YouTube
              videoId={data.wp.acf.videos[0].youtube_link?.split('v=')[1]} // defaults -> null
            />
          </YoutubeWrapper>
        )}
        <PageWrapper
          className="content"
          dangerouslySetInnerHTML={{__html: data.wp.content}}
        />
      </Inner>
      {data.wp.acf.videos && data.wp.acf.videos.length > 1 && (
        <YoutubeInner>
          {loading && <EllipsisLoader className="loader" />}
          <img className="rotate" src={arrow} onClick={prevImg} />
          {data.wp.acf.videos?.slice(1)?.map((video, idx) => {
            if (activeVideo === idx)
              return (
                <YouTube
                  key={`youtube-frame-${idx}`}
                  videoId={video.youtube_link?.split('v=')[1]} // defaults -> null
                  onReady={() => setLoading(false)} // defaults -> noop
                />
              )
          })}
          <img src={arrow} onClick={nextImg} />
        </YoutubeInner>
      )}
      <MainProducts title="Featured" />
    </Layout>
  )
}

export default AboutPage
